import React from 'react';

import { Button } from '@/design-system/atoms/Button';

export const CallToAction = React.forwardRef((props, ref) => {
    const { analytics, ...otherProps } = props;

    return (
        <Button
            ref={ref}
            buttonStyle={Button.STYLE.PRIMARY}
            {...otherProps}
            analytics={analytics}
        />
    );
});

CallToAction.displayName = 'CallToAction';

CallToAction.propTypes = Button.propTypes;
