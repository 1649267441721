import PropTypes from 'prop-types';
import React from 'react';

import { useEmblaCarousel } from '@/hooks/useEmblaCarousel';

import { CarouselContext } from '../contexts/CarouselContext';

export const Root = ({ children, emblaOptions }) => {
    const contentId = React.useId();

    const [emblaRef, emblaApi] = useEmblaCarousel({
        ...emblaOptions,
    });

    const [context, setContext] = React.useState({ contentId, emblaRef, emblaApi });

    const firstRenderRef = React.useRef(true);
    React.useEffect(() => {
        /**
         * At the first render the emblaRef and emblaApi will be set at the
         * moment that we define the `useState`
         */
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return;
        }

        setContext((s) => ({ ...s, emblaRef, emblaApi }));
    }, [emblaRef, emblaApi]);
    React.useEffect(() => {
        if (emblaApi) {
            emblaApi.reInit();
        }
    }, [emblaApi, children]);
    return (
        <CarouselContext.Provider value={{ context, setContext }}>
            {children}
        </CarouselContext.Provider>
    );
};

Root.propTypes = {
    /**
     * [Embla Option](https://www.embla-carousel.com/api/options/)
     */
    emblaOptions: PropTypes.object,
};
