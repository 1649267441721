import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Spacer.module.scss';

export const Spacer = React.forwardRef((props, ref) => {
    const { as: Element = 'div', children, className, ...otherProps } = props;

    return (
        <Element ref={ref} className={classnames(styles['spacer'], className)} {...otherProps}>
            {children}
        </Element>
    );
});

Spacer.displayName = 'Spacer';

Spacer.propTypes = {
    /**
     * Element Type of the root element
     */
    as: PropTypes.elementType,
};
