import { useCallback, useEffect, useRef, useState } from 'react';

const useInfiniteScroll = ({ embla, slides }) => {
    const scrollListenerRef = useRef(() => undefined);
    const listenForScrollRef = useRef(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [pointerIsDown, setPointerIsDown] = useState(false);

    const setPointerDown = useCallback(() => setPointerIsDown(true), []);
    const setPointerNotDown = useCallback(() => setPointerIsDown(false), []);

    const lastSlideIsInView = useCallback(() => {
        if (!embla) return false;
        const lastSlide = embla.slideNodes().length - 1;
        return embla.slidesInView().indexOf(lastSlide) !== -1;
    }, [embla]);

    const onScroll = useCallback(() => {
        if (!embla || !listenForScrollRef.current) return;
        setLoadingMore((isLoadingMore) => {
            if (isLoadingMore) return true;
            const shouldLoadMore = lastSlideIsInView();
            if (shouldLoadMore) {
                listenForScrollRef.current = false;
                embla.off('scroll', scrollListenerRef.current);
            }
            return shouldLoadMore;
        });
    }, [embla, setLoadingMore, lastSlideIsInView]);

    const addScrollListener = useCallback(() => {
        if (!embla) return;
        scrollListenerRef.current = () => onScroll();
        embla.on('scroll', scrollListenerRef.current);
    }, [embla, onScroll]);

    const reloadEmbla = useCallback(() => {
        if (!embla) return;
        const oldEngine = embla.internalEngine();
        embla.reInit();
        const newEngine = embla.internalEngine();
        const copyEngineModules = [
            'scrollBody',
            'location',
            'offsetLocation',
            'previousLocation',
            'target',
        ];
        copyEngineModules.forEach((engineModule) => {
            if (!newEngine[engineModule] || !oldEngine[engineModule]) return;
            Object.assign(newEngine[engineModule], oldEngine[engineModule]);
        });
        newEngine.translate.to(oldEngine.location.get());

        const { index } = newEngine.scrollTarget.byDistance(0, false);
        newEngine.index.set(index);
        newEngine.animation.start();
        setLoadingMore(false);
        listenForScrollRef.current = true;
    }, [embla]);

    useEffect(() => {
        if (!embla) return;
        const engine = embla.internalEngine();
        const boundsActive = engine.limit.reachedMax(engine.target.get());
        engine.scrollBounds.toggleActive(boundsActive);
    }, [embla, slides]);

    useEffect(() => {
        if (!embla || pointerIsDown) return;
        reloadEmbla();
        addScrollListener();
    }, [embla, slides, pointerIsDown, reloadEmbla, addScrollListener, lastSlideIsInView]);

    useEffect(() => {
        if (!embla || (pointerIsDown && !lastSlideIsInView())) return;

        reloadEmbla();
        embla.off('pointerDown', setPointerDown);
        embla.off('pointerUp', setPointerNotDown);
    }, [
        embla,
        slides,
        pointerIsDown,
        setPointerDown,
        setPointerNotDown,
        reloadEmbla,
        lastSlideIsInView,
    ]);

    useEffect(() => {
        if (!embla) return;
        embla.on('pointerDown', setPointerDown);
        embla.on('pointerUp', setPointerNotDown);
        addScrollListener();
    }, [embla, setPointerDown, setPointerNotDown, addScrollListener]);

    return loadingMore;
};

export default useInfiniteScroll;
