import AutoHeight from 'embla-carousel-auto-height';
import useEmblaCarouselOriginal from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import React from 'react';

export const useEmblaCarousel = (emblaOptions = {}) => {
    const [emblaRef, emblaApi] = useEmblaCarouselOriginal(
        {
            loop: false,
            align: 'start',
            skipSnaps: true,
            ...emblaOptions,
        },
        [AutoHeight(), WheelGesturesPlugin()],
    );

    React.useEffect(() => {
        if (!emblaApi) {
            return;
        }

        const handleScroll = () => {
            /**
             * @see https://github.com/davidjerleke/embla-carousel/issues/42#issuecomment-1670221979
             */
            const { limit, target, location, offsetLocation, scrollTo, translate, scrollBody } =
                emblaApi.internalEngine();

            let edge = null;

            if (limit.reachedMax(location.get())) edge = limit.max;
            if (limit.reachedMin(location.get())) edge = limit.min;

            if (edge !== null) {
                offsetLocation.set(edge);
                location.set(edge);
                target.set(edge);
                translate.to(edge);
                translate.toggleActive(false);
                scrollBody.useDuration(0).useFriction(0);
                scrollTo.distance(0, false);
            } else {
                translate.toggleActive(true);
            }
        };

        emblaApi.on('scroll', handleScroll);

        return () => {
            emblaApi.off('scroll', handleScroll);
        };
    }, [emblaApi]);

    return [emblaRef, emblaApi];
};
