import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Card.module.scss';

export const Card = (props) => {
    const { children: childrenProp, className, fullHeight, ...otherProps } = props;

    const children = React.useMemo(() => {
        if (React.isValidElement(childrenProp)) {
            return React.cloneElement(childrenProp, {
                ...childrenProp.props,
                className: classnames(
                    childrenProp.props.className,
                    styles['card--full-width'],
                    fullHeight && styles['card--full-height'],
                ),
            });
        }

        return React.Children.count(childrenProp) > 1 ? React.Children.only(null) : null;
    }, [childrenProp, fullHeight]);

    return (
        <div className={classnames(styles['card'], className)} {...otherProps}>
            {children}
        </div>
    );
};

Card.displayName = 'Card';

Card.propTypes = {
    /**
     * If `true` will inject a CSS class to set height as `100%`
     */
    fullHeight: PropTypes.bool,
};
