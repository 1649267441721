import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';

import { useCarouselContext } from '../../contexts/CarouselContext';
import styles from '../Content/Content.module.scss';

export const InfiniteContent = React.forwardRef((props, ref) => {
    const { className, items, fetchData, loadMoreSlide, ...otherProps } = props;

    const {
        context: { contentId, emblaRef, emblaApi },
    } = useCarouselContext();

    const loadingMore = useInfiniteScroll({ embla: emblaApi, slides: items });

    React.useEffect(() => {
        if (!loadingMore || !fetchData) return;
        const controller = new AbortController();
        fetchData(controller.signal);
        return () => controller.abort();
    }, [loadingMore, fetchData]);

    return (
        <div className={classnames('embla', styles['content'], className)}>
            <ContentContainer
                ref={emblaRef}
                className={classnames('embla__viewport', styles['viewport'])}
                {...otherProps}
            >
                <div
                    id={contentId}
                    role="list"
                    aria-label="content carousel"
                    className={classnames('embla__container', styles['container'])}
                >
                    {Array.isArray(items) && items.length > 0 ? (
                        <>
                            {items.map((node, i) => (
                                <div
                                    key={i}
                                    role="listitem"
                                    className={classnames('embla__slide', styles['slide'])}
                                >
                                    {node}
                                </div>
                            ))}
                            <div
                                key={'load-more'}
                                role="listitem"
                                className={classnames('embla__slide', styles['slide'])}
                            >
                                {loadMoreSlide}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </ContentContainer>
        </div>
    );
});

InfiniteContent.displayName = 'Infinite Content';

InfiniteContent.propTypes = {
    /**
     * Array with slides
     */
    items: PropTypes.arrayOf(PropTypes.node),
    /**
     * fetch data function
     */
    fetchData: PropTypes.func,
};
