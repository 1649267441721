import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ContentContainer } from '@/design-system/atoms/ContentContainer';

import styles from './Footer.module.scss';

export const Footer = React.forwardRef((props, ref) => {
    const { as: Element = ContentContainer, children, className, ...otherProps } = props;

    return (
        <Element ref={ref} className={classnames(styles['footer'], className)} {...otherProps}>
            {children}
        </Element>
    );
});

Footer.displayName = 'Footer';

Footer.propTypes = {
    /**
     * Element Type of the root element
     */
    as: PropTypes.elementType,
};
