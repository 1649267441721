import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ContentContainer } from '@/design-system/atoms/ContentContainer';

import { useCarouselContext } from '../../contexts/CarouselContext';
import styles from './Content.module.scss';

export const Content = React.forwardRef((props, ref) => {
    const { className, items = [], ...otherProps } = props;

    const {
        context: { contentId, emblaRef },
    } = useCarouselContext();

    return (
        <div className={classnames('embla', styles['content'], className)}>
            <ContentContainer
                ref={emblaRef}
                className={classnames('embla__viewport', styles['viewport'])}
                {...otherProps}
            >
                <div
                    id={contentId}
                    role="list"
                    aria-label="content carousel"
                    className={classnames('embla__container', styles['container'])}
                >
                    {Array.isArray(items) && items.length > 0 ? (
                        items.map((node, i) => (
                            <div
                                key={i}
                                role="listitem"
                                className={classnames('embla__slide', styles['slide'])}
                            >
                                {node}
                            </div>
                        ))
                    ) : (
                        <div></div>
                    )}
                </div>
            </ContentContainer>
        </div>
    );
});

Content.displayName = 'Content';

Content.propTypes = {
    /**
     * Array with slides
     */
    items: PropTypes.arrayOf(PropTypes.node),
};
